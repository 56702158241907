import React from 'react';
import { ToastContainer, Toast } from 'react-bootstrap';
import BRAND_CONFIG from '../BRAND_CONFIG';

const ToastManager = ({ page }) => {
    const [show, setShow] = React.useState(true);
    const content = BRAND_CONFIG?.pages?.[page]?.toasts;

    if (!content || !content.length) return null;

    return (
        <ToastContainer position="top-center" className="pt-3">
            <Toast onClose={() => setShow(false)} show={show} delay={3000}>
                {content.map((toast, index) => (
                    <>
                        <Toast.Header>
                            <img
                                src="holder.js/20x20?text=%20"
                                className="rounded me-2"
                                alt=""
                            />
                            <strong className="me-auto">{toast.header}</strong>
                            {/* <small>11 mins ago</small> */}
                        </Toast.Header>
                        <Toast.Body>
                            {toast?.body}
                        </Toast.Body>
                    </>
                ))}
            </Toast>
        </ToastContainer>
    );
};

export default ToastManager;
