import React from 'react';
import { Link } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import {
    Col, Form, Image, Row,
} from 'react-bootstrap';
import SEO from './seo';
import useAuthForm from '../utils/auth/useAuthForm';
import BRAND_CONFIG from '../BRAND_CONFIG';
import HeaderLogo from './header/HeaderLogo';
import ToastManager from './ToastManager';

const { name, logo } = BRAND_CONFIG;

const AuthForm = ({
    title,
    ctaText,
    ctaLoadingText,
    submitType,
}) => {
    if (submitType !== 'login' && submitType !== 'register') {
        console.error('AuthForm submitType must be one of: "login", "register".');
    }
    const {
        fields, state, onChange, onSubmit, disabled,
    } = useAuthForm();

    const isLogin = submitType === 'login';
    const alternativeText = isLogin ? 'Don\'t have an account?' : 'Already have an account?';
    const alternativeCtaText = isLogin ? 'Register' : 'Log In';
    const alternativeLink = isLogin ? '/register' : '/login';

    return (
        <>
            <SEO title={title} />
            <Container fluid className="page-wrapper">
                <Col />
                <ToastManager page={submitType} />
                <Col className="mt-3 sub-form-container px-3 flex-sm-fill flex--grow-1">
                    <HeaderLogo title={`${title} ${name}`} logo={logo} logoSize={100} />

                    <Form className="form-signin">
                        <Form.Group className="form-label-group mt-4">
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="email"
                                id="email"
                                className="form-control"
                                required
                                value={fields.email}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <Form.Group className="form-label-group">
                            <Form.Label for="password">Password</Form.Label>
                            <Form.Control
                                type="password"
                                id="password"
                                className="form-control"
                                required
                                value={fields.password}
                                onChange={onChange}
                            />
                        </Form.Group>
                        <div className="d-grid">
                            <Button
                                onClick={() => onSubmit(submitType)}
                                disabled={disabled}
                                className="mb-3 mt-4"
                            >
                                {state.loading ? ctaLoadingText : ctaText}
                            </Button>
                        </div>
                        <p className="text-center text-danger">{state.errorMessage}</p>
                        { isLogin && (
                            <p className="micro-font text-center">
                                <Link className="toggle-btn" to="/forgot-my-password">
                                    Forgot my password
                                </Link>
                            </p>
                        )}
                        <p className="micro-font text-center">
                            {alternativeText}
                            {' '}
                            <Link className="toggle-btn" to={alternativeLink}>
                                {alternativeCtaText}
                            </Link>
                        </p>

                    </Form>
                </Col>
                <Row className="info-row mt-5 d-flex align-items-sm-end">
                    <Col className="footer-notes mt-4">
                        <p className="agreement micro-font">
                            By clicking
                            {' '}
                            <b>{ctaText}</b>
                            {' '}
                            you agree to our
                            {' '}
                            <a href={BRAND_CONFIG.links.privacy} target="_blank" className="toggle-btn link-btn" rel="noreferrer">
                                Privacy Policy
                            </a>
                            .
                        </p>
                        <p className="micro-font">
                            Need help? Visit
                            {' '}
                            <a className="link-btn" target="_blank" href={BRAND_CONFIG.links.support} rel="noreferrer">
                                Support Center
                            </a>
                        </p>
                        <p className="micro-font">powered by GENFLOW</p>
                    </Col>
                </Row>

            </Container>
        </>
    );
};

export default AuthForm;
