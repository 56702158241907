import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';
import React from 'react';
import BRAND_CONFIG from '../../BRAND_CONFIG';
import { register, login } from '../auth';

const { errors } = BRAND_CONFIG.forms;

export default () => {
    const [state, setStateBase] = React.useState({
        errorMessage: '',
        loading: false,
    });

    const [fields, setFields] = React.useState({
        email: '',
        password: '',
    });

    const setState = (stateChanges) => {
        setStateBase({
            ...state,
            ...stateChanges,
        });
    };

    const identify = async () => {
        const userInfo = Auth ? await Auth.currentUserInfo() : null;
        if (userInfo) {
            try {
            // Setup analytics
                const { attributes, username } = userInfo;
                await window.analytics.identify(username, {
                    username,
                    email: attributes.email,
                    firstName: attributes.given_name,
                    lastName: attributes.family_name,
                });
            } catch (error) {
                console.error(error);
            }
        }
    };

    const onRegister = async (fields) => {
        try {
            await register(fields);
            await identify();
            // setState({ loading: false });
        } catch (error) {
            setState({ loading: false });
            if (error.code === 'UsernameExistsException') {
                setState({ errorMessage: errors.existingUser });
                return false;
            }
            if (error.code === 'InvalidParameterException') {
                if (error.message.includes('password')) {
                    console.log(error.code);
                    setState({ errorMessage: errors.invalidPassword });
                    return false;
                }
                console.log(error.code);
                setState({ errorMessage: error.message });
                return false;
            }
            setState({ errorMessage: errors.unknownError });
            console.log('unknown', error);
        }
    };

    const onLogin = async (formData) => {
        try {
            await login(formData);
            await identify();
            return;
        } catch (e) {
            console.log(e);
            if (e.code === 'UserNotFoundException' || e.code === 'NotAuthorizedException') {
                setState({
                    ...state,
                    errorMessage: errors.userNotFound,
                });
            }
        }
    };

    const onChange = (event) => {
        setFields({
            ...fields,
            [event.target.id]: event.target.value,
        });
    };

    const onSubmit = (submitType) => {
        fields.email = fields.email.toLowerCase();
        setState({ loading: true, errorMessage: null });
        if (submitType === 'login') {
            onLogin(fields);
        } else {
            onRegister(fields);
        }
    };

    const disabled = !fields.email.length || !fields.password.length || state.loading;

    return {
        fields,
        state,
        setState,
        onChange,
        onSubmit,
        disabled,
    };
};
